import { Form } from "antd";
import { useField } from "formik";
import { useEffect, useRef } from "react";

function Input({
  setRef = false,
  formItemClass = "",
  icon,
  name,
  setFieldValue,
  addonAfter,
  ...rest
}) {
  const ref = useRef();
  const [field, meta] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  useEffect(() => {
    if (setRef) {
      setRef(ref);
    }
  }, [setRef]);

  return (
    <Form.Item
      className={`${formItemClass}`}
      help={meta.error && meta?.error && meta?.touched ? meta.error : ""}
      validateStatus={config.error ? "error" : "success"}
    >
      {icon}
      <input
        type="text"
        ref={ref}
        {...field}
        {...rest}
        style={{ color: "#333" }}
      />
      {addonAfter && addonAfter}
    </Form.Item>
  );
}

export default Input;
