import axios from "axios";
import React, { useState } from "react";
import config from "../config";
import { modalNotification } from "../utils";
import { Spin } from "antd";

export default function Test() {
  const [response, setResponseMessage] = useState();
  const [response1, setResponseMessage1] = useState();
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const handleSubmit = async () => {
    setLoading1(true);
    let data = {
      dbUser: config?.USER_ID,
      dbPassword: config?.USER_PASSWORD,
      srvId: 100,
      procName: "StickN_FillList",
      param: {
        rowsList: [
          {
            cols: {
              pname: "Pid",
              value: config?.WMS_STORE_ID,
            },
          },
        ],
        header: [],
        name: "param",
      },
      timeout: 60,
      host: config?.HOST_NAME,
      os: config?.HOST_OS,
      rid: config?.HOST_RID,
      key: config?.HOST_KEY,
    };
    // e.preventDefault();
    try {
      const headers = {
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "https://webapp.nsbbpo.in/OndRestServer/data/getds",
        data,
        {
          headers,
        }
      );

      if (response) {
        modalNotification({
          type: "success",
          message: "api calling success",
        });
        setResponseMessage1(response.data.message);
      }
    } catch (error) {
      console.log("error", error);

      setResponseMessage(error);
      //   setResponseMessage("Error occurred while making the request.");
    }
    setLoading1(false);
  };
  const handleSubmithttp = async () => {
    setLoading(true);
    let data = {
      dbUser: config?.USER_ID,
      dbPassword: config?.USER_PASSWORD,
      srvId: 100,
      procName: "StickN_FillList",
      param: {
        rowsList: [
          {
            cols: {
              pname: "Pid",
              value: config?.WMS_STORE_ID,
            },
          },
        ],
        header: [],
        name: "param",
      },
      timeout: 60,
      host: config?.HOST_NAME,
      os: config?.HOST_OS,
      rid: config?.HOST_RID,
      key: config?.HOST_KEY,
    };
    // e.preventDefault();
    try {
      const headers = {
        Accept: "*/*",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        "http://webapp.nsbbpo.in/OndRestServer/data/getds",
        data,
        {
          headers,
        }
      );
      if (response) {
        modalNotification({
          type: "success",
          message: "api calling success",
        });
        setResponseMessage1(response.data.message);
      }

      // Response message from API
    } catch (error) {
      setResponseMessage1(error);
      //   setResponseMessage("Error occurred while making the request.");
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="ps-5">
        <button onClick={() => handleSubmit()} className="mb-2">
          Api Call With HTTPS
        </button>{" "}
        {loading1 && <Spin />}
        <p>
          <h4>Headers</h4>
          URL - {response?.config?.url}
          <br />
          Accept - {response?.config?.headers?.Accept}
          <br />
          Cache-Control - {response?.config?.headers["Cache-Control"]}
          <br />
          Connection - {response?.config?.headers?.Connection}
          <br />
          Content-Type - {response?.config?.headers["Content-Type"]}
        </p>
        <h5>Payload</h5>
        {response?.config?.data}
      </div>

      <div className="ps-5 mt-3">
        <button onClick={() => handleSubmithttp()} className="mb-2">
          Api Call With HTTP
        </button>{" "}
        <p>
          {loading && <Spin />}
          <h4>Headers</h4>
          URL - {response1?.config?.url}
          <br />
          Accept - {response1?.config?.headers?.Accept}
          <br />
          Cache-Control - {response1?.config?.headers["Cache-Control"]}
          <br />
          Connection - {response1?.config?.headers?.Connection}
          <br />
          Content-Type - {response1?.config?.headers["Content-Type"]}
        </p>
        <h5>Payload</h5>
        {response1?.config?.data}
      </div>

      {/* <div className="ps-5">
        {" "}
        <p>
          <h4>Headers</h4>
          Accept - {response?.config?.headers?.Accept}
          <br />
          Cache-Control - {response?.config?.headers["Cache-Control"]}
          <br />
          Connection - {response?.config?.headers?.Connection}
          <br />
          Content-Type - {response?.config?.headers["Content-Type"]}
        </p>
      </div> */}
      {/* {response} */}
    </div>
  );
}
