import React from "react";
import config from "../../../config";

function Spinner({ width = 110 }) {
  return (
    <>
      <img src={`${config?.IMAGE_URL}loader.gif`} width={width} alt="loader" />
    </>
  );
}

export default Spinner;