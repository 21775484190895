/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useIndexedDB } from "react-indexed-db-hook";

export default function useFetch(state) {
  const { add, update, getAll, getByID } = useIndexedDB(state?.name);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const tableName = state?.name;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!state?.type && !state?.id) {
          state.type = "getAll";
        } else if (state?.id) {
          state.type = "getById";
        }
        if (state?.type === "getAll") {
          const response = await getAll().then((res) => {
            return res[0];
          });
          setData(response);
        } else if (state?.type === "getById") {
          const response = await getByID(state?.id).then((res) => {
            return res;
          });
          setData(response);
        } else if (state?.id && state?.type === "update") {
          delete state.type;
          delete state.name;
          update(state?.payload);
        } else if (!state?.id && state?.type === "add") {
          delete state.type;
          delete state.name;
          add(state);
        }
      } catch (error) {
        setError(error);
      }
    };
    if (state?.name) {
      fetchData();
    }
  }, [state?.name]);

  return { [tableName]: data, error };
}
