import React, { useEffect, useRef, useState } from "react";
import { Select as AntSelect, Form, Empty } from "antd";
import config from "../../config";
import BarcodeReader from "react-barcode-reader";
function CustomSelect({
  uniqueKey = "default-random-key",
  children,
  arrayOfData,
  label = "",
  onSelect,
  callback,
  loading,
  selectedValue,
  onKeyChangeValue = false,
  getBarCodeValue,
  defaultValue,
  resetInput,
  setSelectRef,
  setCustomSearchValue = false,
  ...rest
}) {
  const [qty, setQTY] = useState(false);
  const [value, setValue] = useState([]);
  const [open, setOpen] = useState(false);
  const [emptyElm, setEmptyElm] = useState("");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [state, setState] = useState();
  const [barcodeValue, setBarcodeValue] = useState();
  const selectRef = useRef(null);
  const lastKeyPressTime = useRef(0);
  const [backspaceKey, setBackSpaceKey] = useState(false);

  const onChangeValue = (val) => {
    let tempArr = val.target.value.trim().split("");

    setValue(tempArr);
    if (tempArr?.length > 0) {
      setOpen(false);
      let dropHide = document.querySelector(
        `.ant-select-dropdown-${uniqueKey}`
      );
      if (dropHide !== null) {
        dropHide?.childNodes[0]?.classList?.remove("d-none");
        // dropHide?.classList.remove("ant-select-dropdown-hidden");
      }
    }
    if (tempArr[0] === "+" || tempArr[0] === "-" || tempArr[0] === 0) {
      selectRef.current.focus();
      setIsInputFocused(false);
      setQTY(true);
      setOpen(true);
      setTimeout(() => {
        let dropHide1 = document.querySelector(
          `.ant-select-dropdown-${uniqueKey}`
        );
        setEmptyElm(dropHide1);
      }, 0);
    }
    // else if (
    //   !/^[a-zA-Z]$/.test(val?.key) &&
    //   val?.key !== "ArrowDown" &&
    //   val?.key !== "ArrowUp" &&
    //   val?.key !== "Backspace" &&
    //   val?.key !== " " &&
    //   val?.key === "8"
    //   // gunMaChine
    // ) {
    //   const listElement = document.querySelector(".rc-virtual-list-holder");
    //   if (listElement !== null) {
    //     listElement.classList.add("d-none");
    //   }
    //   selectRef.current.blur();
    //   setIsInputFocused(true);
    // } else {
    //   const listElement = document.querySelector(".rc-virtual-list-holder");
    //   if (listElement !== null) {
    //     listElement.classList.remove("d-none");
    //   }
    // }

    if (val?.code === "Enter") {
      // if (getBarCodeValue) {
      //   getBarCodeValue(val.target.value);
      // }
      selectRef.current.focus();
      setIsInputFocused(false);
      setOpen(false);
      setQTY(false);
    } else if (tempArr[0] !== "+") {
      setQTY(false);
      setOpen(true);
    } else if (val.target.value === "") {
      setOpen(false);
    }
    if (val?.keyCode === 13) {
      if (value[0] === "+" || value[0] === "-" || value[0] === "0") {
        if (onKeyChangeValue) {
          onKeyChangeValue(value);
        }
        setOpen(false);
        setQTY(false);
      } else {
        setQTY(false);
      }
    }
  };

  const handleChangeSelect = (value) => {
    if (!qty) {
      selectedValue(value);
      setOpen(false);
      setIsInputFocused(false);
      selectRef.current.focus();
    }
  };

  useEffect(() => {
    if (emptyElm !== null && emptyElm !== "" && value[0] === "+") {
      emptyElm?.childNodes[0]?.classList.add("d-none");
    }
  }, [emptyElm, value]);

  const handleClickOutside = () => {
    setOpen(false);
  };

  const handleScan = (data) => {
    if (data) {
      setBarcodeValue(`${state}${data}`);
      selectRef.current.focus();
      setOpen(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.addEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (barcodeValue) {
      if (getBarCodeValue) {
        getBarCodeValue(barcodeValue);
      }
      setBarcodeValue();
      selectRef.current.focus();
    }
  }, [barcodeValue]);

  const onSearch = (event) => {
    const currentTime = new Date().getTime();
    if (!backspaceKey && currentTime - lastKeyPressTime.current < 100) {
      setState(event);
      const listElement = document.querySelector(".rc-virtual-list-holder");
      if (listElement !== null) {
        listElement.classList.add("d-none");
      }
      selectRef.current.blur();
      setIsInputFocused(true);
    } else {
      const listElement = document.querySelector(".rc-virtual-list-holder");
      if (listElement !== null) {
        listElement.classList.remove("d-none");
      }
    }

    lastKeyPressTime.current = currentTime;
  };

  return (
    <Form.Item className="mb-0" label={label}>
      <AntSelect
        ref={selectRef}
        popupClassName={`ant-select-dropdown-${uniqueKey}`}
        notFoundContent={
          <Empty
            image={`${config.IMAGE_URL}searchNoFound.svg`}
            description="Search Not Found"
          />
        }
        id={`select-${uniqueKey}`}
        size="large"
        lang="en"
        open={open}
        onSearch={onSearch}
        value={barcodeValue || defaultValue || undefined}
        loading={loading}
        onKeyUp={onChangeValue}
        onKeyDown={(e) => {
          if (e.key === "Backspace") {
            setBackSpaceKey(true);
          } else {
            setBackSpaceKey(false);
          }
        }}
        {...rest}
        filterOption={(input, option) => {
          let inputVal = input.trim();
          if (setCustomSearchValue) {
            setCustomSearchValue(inputVal);
          }
          if (option?.BCGunStrict) {
            if (option?.BCGunStrict === "0") {
              return (
                (inputVal !== "-" || inputVal !== " ") &&
                option.label?.toLowerCase()?.includes(inputVal?.toLowerCase())
              );
            }
          } else {
            return (
              (inputVal !== "-" || inputVal !== " ") &&
              option.label?.toLowerCase()?.includes(inputVal?.toLowerCase())
            );
          }
        }}
        onChange={onSelect || handleChangeSelect}
        options={
          !qty &&
          arrayOfData?.length > 0 &&
          arrayOfData?.map((item, index) => ({
            value: item?.id,
            label: item?.name,
            BCGunStrict: item?.BCGunStrict ? item?.BCGunStrict : "",
          }))
        }
      />
      {isInputFocused && (
        <BarcodeReader
          onError={handleError}
          onScan={handleScan}
          minLength={1}
        />
      )}
    </Form.Item>
  );
}

export default CustomSelect;
