import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";

const CustomCalendarRangePicker = ({
  ref,
  selected,
  setDateChange,
  handleClickOutside,
  handleCalendarOpen,
  startDate,
  endDate,
  minDate = "",
  disabled,
  ...rest
}) => {
  const toggleCalendar = () => {
    handleClickOutside((prev) => !prev);
  };
  return (
    <>
      <div className="date-picker-wrapper w-100">
        <DatePicker
          {...rest}
          ref={ref}
          selected={selected}
          className="form-control shadow-none border-px w-100 font-style form-input"
          onChange={setDateChange}
          dateFormat="dd/MM/yyyy"
          placeholderText="dd/mm/yyyy"
          open={handleCalendarOpen}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          disabled={disabled}
          onClickOutside={() => handleClickOutside(false)}
          onSelect={() => handleClickOutside(false)}
          onFocus={() => handleClickOutside(true)}
        />
        <button
          disabled={disabled}
          type="button"
          onClick={toggleCalendar}
          className="calendar-icon-button"
        >
          <FaCalendar />
        </button>
      </div>
    </>
  );
};

export default CustomCalendarRangePicker;
