import APIrequest from "../axios";
export const userlogin = (payload) => {
  return APIrequest("v1/sopos/Login/posLogin", payload, "POST");
};

export const getStoreItemList = (payload, token) => {
  return APIrequest("v1/sopos/Product/getStoreWiseProduct", payload, "POST", {
    token: token,
  });
};
// export const getStoreItemList = (payload, token) => {
//   return APIrequest(
//     "v1/sopos/Commonpos/getStoreWiseItemRate",
//     payload,
//     "POST",
//     {
//       token: token,
//     }
//   );
// };

export const getAllACMByStore = async (payload, token) => {
  const res = await APIrequest(
    "v1/sopos/Commonpos/getStoreWiseACM",
    payload,
    "POST",
    {
      token: token,
    }
  );
  return res?.data;
};
export const getRegisterCustomer = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Commonpos/getOffineCustomer",
    payload,
    "POST"
  );
  return res?.data;
};
export const newCustomerRegisterService = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Commonpos/registerOfflineUsers",
    payload,
    "POST"
  );
  return res?.data;
};

export const paymentModeService = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/getPaymentMode",
    payload,
    "POST"
  );
  return res?.data;
};
export const saveBillService = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/saveStoreOrderBill",
    payload,
    "POST"
  );
  return res?.data;
};
export const billingHistoryService = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/getPaymentHistory",
    payload,
    "POST"
  );
  return res?.data;
};

export const cocoStoresListService = async (payload, token) => {
  const res = await APIrequest(
    "v1/sopos/Commonpos/getAllStoreLocationWise",
    payload,
    "POST",
    {
      token: token,
    }
  );
  return res?.data;
};
export const verifyPaymentService = async (payload, token) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/verifyEDCPayment",
    payload,
    "POST",
    {
      token: token,
    }
  );
  return res?.data;
};

export const sendTableOrderData = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/syncStoreOrderBill",
    payload,
    "POST"
  );
  return res?.data;
};

export const storeWiseDeviceService = async (payload) => {
  const res = await APIrequest(
    "v1/sopos/Storeorder/getStoreWisePaytmEDCDevice",
    payload,
    "POST"
  );
  return res?.data;
};
