import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  CommonButton,
  Input as TextInput,
  Password as TextPassword,
} from "../../../components";
import { validation } from "./validation";
import Link from "antd/es/typography/Link";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import "../../../styles/Pages/login-form.css";

export default function UserLoginForm({ onSubmit, loading }) {
  const [showPassword, setShowPassword] = useState(false);
  let initialValues = {
    emp_id: "",
    password: "",
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validation()}
      onSubmit={(e) => {
        onSubmit(e);
      }}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form>
            <div className="text-content-center">
              <h1 className="login-heading">Login</h1>
            </div>
            <div>
              <label for="text" className="input-label">
                User ID
              </label>
              <TextInput
                id="emp_id"
                className="input-box"
                name="emp_id"
                variant="standard"
                type="text"
                placeholder="Enter your user id"
                setFieldValue={props.handleChange}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value?.length > 20) {
                    e.target.value = value?.slice(0, 20);
                  }
                  props.handleChange(e);
                }}
              />
            </div>
            <div>
              <label className="input-label" htmlFor="email">
                Password
              </label>
              <div className="input-field password-wrapper">
                <TextPassword
                  className="input-pass-field"
                  name="password"
                  floatingLabel={false}
                  placeholder="Enter your password"
                  setFieldValue={props.handleChange}
                  type={showPassword ? "text" : "password"}
                  toggleIcon={
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      style={{ color: "#c78187" }}
                      className="password-toggle-button"
                      data-target="password"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </Link>
                  }
                  // icon={
                  //     <div className="form-icon form-icon-left">
                  //         <em className="icon ni ni-lock" />
                  //     </div>
                  // }
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value?.length > 20) {
                      e.target.value = value?.slice(0, 20);
                    }
                    props.handleChange(e);
                  }}
                />
              </div>
            </div>

            <div className="login-btn-group gap-3 offActive">
              <CommonButton
                htmlType="submit"
                className="login-btn btn-continue"
                type="submit"
                loading={loading}
              >
                Sign In
              </CommonButton>
              <CommonButton
                htmlType="reset"
                className="cancel-btn offActive btn-continue"
                // type="submit"
                onClick={() => window.location.reload()}
              >
                Cancel
              </CommonButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
