import axios from "axios";
import config from "../config";
import { getLocalStorageToken, modalNotification } from "../utils";
const { API_BASE_URL } = config;
const client = axios.create({});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 400) {
      modalNotification({
        type: "error",
        // message: error?.response?.data?.message,
        message: "Token not found, please login again.",
      });
    } else if (error?.response?.status === 500) {
      modalNotification({
        type: "error",
        message: error?.response?.statusText,
        // message: "Token not found, please login again.",
      });
    } else if (error?.response?.status === 200) {
      return error?.response;
    }
    //  else {
    //   return error?.response;
    // }
  }
);

const APIrequest = (endpoint, payload = {}, method = "get", headers = {}) => {
  const tokens = getLocalStorageToken();

  if (tokens) {
    headers.token = tokens;
  }
  let axiosConfig = {
    method: method.toLowerCase(),
  };
  if (endpoint !== "v1/sopos/Login/posLogin") {
    axiosConfig.headers = headers;
  }
  if (method === "get") {
    axiosConfig.params = payload;
  } else {
    axiosConfig.data = payload;
  }

  return client(`${API_BASE_URL}${endpoint}`, axiosConfig);
};
export default APIrequest;
