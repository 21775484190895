import React from 'react'
import CommonButton from '../Button/index.btn'

const SaveModal = ({handleCloseModal, saveMsg}) => {
  return (
    <>
        <div className='d-flex flex-column py-2'>
            <div className='py-2'>
                <div className='d-flex justify-content-center font-style fw-semibold'>
                    {saveMsg}
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <CommonButton className="common-btn btn-width" onClick={handleCloseModal}>OK</CommonButton>
            </div>
        </div>
    </>
  )
}

export default SaveModal;