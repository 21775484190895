import React, { useState, useEffect } from 'react'
import CommonButton from '../Button/index.btn'
import { useIndexedDB } from 'react-indexed-db-hook';
import { modalNotification, useFetch } from '../../utils';
import { getChangeUserPassword } from '../../services/ChangePassword';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ChangePassword = ({ setChangePasswordModal }) => {
    const { getAll } = useIndexedDB("storeItems");
    const { userDataAndStore } = useFetch({ name: "userDataAndStore" });
    const [wmsStoreId, setWmsStoreId] = useState();
    const [userId, setUserId] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordMatch, setIsPasswordMatch] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePasswordChange = (e) => {
        let password = e.target.value;
        if(password?.length > 15){
            password = password?.slice(0, 15);
        }
        e.target.value = password;
        setNewPassword(password);
    };

    const handleConfirmPasswordChange = (e) => {
        let confirmPass = e.target.value;
        if(confirmPass?.length > 15){
            confirmPass = confirmPass?.slice(0, 15);
        }
        e.target.value = confirmPass;
        setConfirmPassword(confirmPass);
    };

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword((prevState) => !prevState);
    };

    const togglePasswordVisibility = () => {
        setShowNewPassword((prevState) => !prevState);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleSubmit = async () => {
        if (!newPassword) {
            setIsPasswordMatch(["Please enter the new password."]);
            return;
        }
        if (!/[A-Z]/.test(newPassword)) {
            setIsPasswordMatch("Password must contain at least one uppercase letter.");
            return;
        }
        if (!/[a-z]/.test(newPassword)) {
            setIsPasswordMatch("Password must contain at least one lowercase letter.");
            return;
        }
        if (!/[0-9]/.test(newPassword)) {
            setIsPasswordMatch("Password must contain at least one number.");
            return;
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
            setIsPasswordMatch("Password must contain at least one special character.");
            return;
        }
        if (/\s/.test(newPassword)) {
            setIsPasswordMatch("Password must not contain any whitespace.");
            return;
        }
        if (newPassword.length < 6) {
            setIsPasswordMatch("Password must be at least 6 characters long.");
            return;
        }
        if (!confirmPassword) {
            setIsPasswordMatch("Please enter the confirm password.");
            return;
        } else if (newPassword !== confirmPassword) {
            setIsPasswordMatch("Passwords do not match.");
            return;
        } else {
            let payload = {
                wms_store_id: "2904",
                employee_id: userId,
                current_pass: currentPassword,
                new_pass: newPassword
            }
            const res = await getChangeUserPassword(payload);
            if (res?.status) {
                modalNotification({
                    type: "success",
                    message: res?.message
                });
                setCurrentPassword("");
                setNewPassword("");
                setConfirmPassword("");
                setChangePasswordModal(false);
            } else {
                modalNotification({
                    type: "error",
                    message: res?.message
                });
            }

        }
    };

    useEffect(() => {
        getAll().then((personsFromDB) => {
            setWmsStoreId(personsFromDB[0]?.wms_store_id);
        })
        let userIds = userDataAndStore?.userData?.employee_id;
        setUserId(userIds);
    }, [wmsStoreId]);


    return (
        <>
            <div className='p-2'>
                <div className='text-center font-style fw-semibold mb-2'>Change Password</div>
                <div className="d-flex gap-2 align-items-center gap-2">
                    <span className='font-style white-space fw-semibold input-w-120'>Current Password<sup className='font-style fw-bolder err-msg-color'>*</sup></span>
                    <div className="position-relative w-100">
                        <input
                            className='form-control form-input font-style'
                            placeholder='Enter Current Password'
                            autoComplete="new-password"
                            type={showCurrentPassword ? "text" : "password"}
                            value={currentPassword}
                            onChange={(e) => {
                                let value = e.target.value;
                                if(value?.length > 15){
                                    value = value?.slice(0, 15);
                                }
                                e.target.value = value;
                                setCurrentPassword(value)}
                            }
                        />
                        <span
                            className="position-absolute check-eye end-0 top-50 translate-middle-y me-3 cursor-pointer"
                            onClick={toggleCurrentPasswordVisibility}
                        >
                            {showCurrentPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>
                </div>
                <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center gap-2 mt-2'>
                        <span className='font-style white-space fw-semibold input-w-168'>New Password<sup className='font-style fw-bolder err-msg-color'>*</sup></span>
                        <div className="position-relative w-100">
                            <input
                                className='form-control form-input font-style'
                                placeholder="Enter New Password"
                                autoComplete="new-password"
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={handlePasswordChange}
                            />
                            <span
                                className="position-absolute check-eye end-0 top-50 translate-middle-y me-3 cursor-pointer"
                                onClick={togglePasswordVisibility}
                            >
                                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-2 mt-2'>
                    <span className='font-style white-space fw-semibold input-w-120'>Confirm Password<sup className='font-style fw-bolder err-msg-color'>*</sup></span>
                    <div className="position-relative w-100">
                        <input
                            className='form-control form-input font-style'
                            placeholder='Enter Confirm Password'
                            autoComplete="new-password"
                            type={showConfirmPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                        />
                        <span
                            className="position-absolute check-eye end-0 top-50 translate-middle-y me-3 cursor-pointer"
                            onClick={toggleConfirmPasswordVisibility}
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </span>
                    </div>
                </div>
                {isPasswordMatch && (
                    <div className='pt-2'>
                        <div className='ms-0 ps-0'>
                            <span className='password-err-msg'>{isPasswordMatch}</span>
                        </div>
                    </div>
                )}

                <div className='d-flex justify-content-center gap-2 mt-3'>
                    <CommonButton className="common-btn" onClick={() => handleSubmit()}>Update</CommonButton>
                    <CommonButton className="common-cancel-btn" onClick={() => setChangePasswordModal(false)}>Cancel</CommonButton>
                </div>
            </div>
        </>
    )
}

export default ChangePassword