/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useIndexedDB } from "react-indexed-db-hook";
import {
  billingHistoryService,
  getAllACMByStore,
  paymentModeService,
  cocoStoresListService,
  sendTableOrderData,
} from "./Store";
import { logger } from "../utils";
import moment from "moment";
import { CommonGetStoreItemsAPI } from "./getStoreItemsApi";

function CommonApiCalling({
  syncData,
  setSYNCData,
  getAllAPI,
  setLoading,
  setAPIState,
  syncBill = false,
}) {
  const { add, getByID, update } = useIndexedDB("storeItems");
  const mode = useIndexedDB("userMode");
  const acmOffersDB = useIndexedDB("acmOffers");
  const userDataAndStore = useIndexedDB("userDataAndStore");
  const paymentModeStore = useIndexedDB("paymentMode");
  const billHistoryListStore = useIndexedDB("billHistoryList");
  const cocoStoresList = useIndexedDB("cocoStoresList");
  const syncStore = useIndexedDB("syncTime");
  const [token, setToken] = useState("");
  const saveBills = useIndexedDB("saveBills");
  // const token = getLocalStorageToken();
  const [, setStoreId] = useState();

  const fetchStoreItems = async (load = false) => {
    if (load && setLoading) {
      setLoading(true);
    }
    try {
      await CommonGetStoreItemsAPI({ getByID, mode, token, add, update });
    } catch (error) {
      logger(error);
    }
    if (load && setLoading) {
      setLoading(false);
    }
  };

  const getACMOffers = async () => {
    try {
      const StoreIDRes = await getByID(1).then((personsFromDB) => {
        setStoreId(personsFromDB?.storeId);
        return personsFromDB?.wms_store_id;
      });
      let checkData = await getByID(1).then((personsFromDB) => {
        return personsFromDB;
      });

      let payload = {
        wms_store_id: StoreIDRes,
        location_id: checkData?.location_id,
      };
      let resMode = await mode.getByID(1).then((personFromDB) => {
        return personFromDB;
      });
      if (resMode.mode === "onLine") {
        const res = await getAllACMByStore(payload, token);
        if (res?.status) {
          if (res?.data) {
            acmOffersDB?.clear();
            acmOffersDB.add(res?.data);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    return true;
  };

  const getPaymentMode = async () => {
    try {
      let resMode = await mode.getByID(1).then((personFromDB) => {
        return personFromDB;
      });
      if (resMode.mode === "onLine") {
        const res = await paymentModeService();
        if (res?.status) {
          paymentModeStore?.clear();
          paymentModeStore.add(res?.data);
        }
      }
    } catch (error) {
      logger(error);
    }
  };

  const getBillingHistory = async () => {
    try {
      const toDate = new Date();
      toDate?.setDate(toDate?.getDate() - 5);
      let checkData = await getByID(1).then((personsFromDB) => {
        return personsFromDB;
      });

      // console.log("toDate", toDate);
      let payload = {
        from_date: moment(toDate).format("YYYY-MM-DD"),
        to_date: moment(new Date())?.format("YYYY-MM-DD"),
        wms_store_id: checkData?.wms_store_id,
        mobile_number: "",
      };
      let resMode = await mode.getByID(1).then((personFromDB) => {
        return personFromDB;
      });
      if (resMode?.mode === "onLine") {
        const res = await billingHistoryService(payload);
        if (res?.status) {
          billHistoryListStore?.clear();
          const response = await saveBills?.getAll().then((res) => {
            return res[0];
          });
          let arr = res?.data?.orders?.filter(
            (item) => item?.status !== "FAIL" && item
          );
          let data = arr;
          let finalArr = [];
          if (response?.id) {
            finalArr = data.concat(response?.data);
            billHistoryListStore.add({ data: finalArr });
          } else {
            billHistoryListStore.add({ data: arr });
          }
        } else {
          billHistoryListStore?.clear();
          billHistoryListStore.add({ data: [] });
        }
      }
    } catch (error) {
      logger(error);
    }
  };

  const getCocoStoresList = async () => {
    try {
      let checkData = await getByID(1).then((personsFromDB) => {
        return personsFromDB;
      });
      let payload = {
        location_id: checkData?.location_id,
        wms_store_id: checkData?.wms_store_id,
      };
      let resMode = await mode.getByID(1).then((personFromDB) => {
        return personFromDB;
      });
      if (resMode.mode === "onLine") {
        const res = await cocoStoresListService(payload, token);
        if (res?.status) {
          cocoStoresList?.clear();
          const filterdedData = res?.data?.filter((item) => {
            return item?.wms_store_id !== checkData?.wms_store_id;
          });
          cocoStoresList.add({ data: filterdedData });
        }
      }
    } catch (error) {
      logger(error);
    }
  };
  const callAPIS = async () => {
    setLoading(true);
    try {
      syncStore?.clear();
      syncStore?.add({ syncTime: moment(new Date())?.format("DD-MMMM h:mm") });
      await getACMOffers();
      await getPaymentMode();
      // await getStoreItems();
      await getBillingHistory();
      await getCocoStoresList();
    } catch (error) {
      logger(error);
    }
    setLoading(false);
    setAPIState(false);
  };

  const getToken = async () => {
    let checkData = await userDataAndStore?.getAll().then((personsFromDB) => {
      return personsFromDB;
    });
    setToken(checkData[0]?.token);
  };

  const syncBillData = async () => {
    const response = await saveBills?.getAll().then((res) => {
      return res[0];
    });
    try {
      const payload = {
        orders: response?.data,
      };
      const data = await sendTableOrderData(payload);
      if (data?.status) {
        getBillingHistory();
        saveBills.clear();
      } else {
        setTimeout(() => {
          syncBillData();
        }, 30000);
      }
    } catch (err) {
      console.log(err);
      if (response?.data?.length > 0) {
        setTimeout(() => {
          syncBillData();
        }, 30000);
      }
    }
  };

  const handleOnline = async () => {
    let response = await saveBills?.getAll().then((res) => {
      return res[0];
    });
    if (navigator.onLine && response?.data?.length > 0) {
      syncBillData();
    }
  };

  useEffect(() => {
    if (syncBill) {
      handleOnline();
    }
  }, [syncBill]);

  useEffect(() => {
    if (token) {
      const interval = setInterval(() => {
        syncStore.clear();
        syncStore.add({ syncTime: moment(new Date())?.format("DD-MMMM h:mm") });
        // getStoreItems();
        fetchStoreItems();
        getACMOffers();
        getPaymentMode();
        getBillingHistory();
        getCocoStoresList();
      }, 900000);
      return () => clearInterval(interval);
    }
  }, [token]);

  useEffect(() => {
    if (syncData) {
      syncStore.clear();
      syncStore.add({ syncTime: moment(new Date())?.format("DD-MMMM h:mm") });
      //  getStoreItems();
      fetchStoreItems("load");
      getPaymentMode();
      getACMOffers();
      getBillingHistory();
      getCocoStoresList();
      setSYNCData(false);
    }
  }, [syncData]);

  useEffect(() => {
    if (getAllAPI && token) {
      callAPIS();
    }
  }, [getAllAPI, token]);

  useEffect(() => {
    getToken();
  }, []);
  return <></>;
}

export default CommonApiCalling;
