import { Table as AntTable, Empty } from "antd";
import React from "react";
import config from "../../../config";

export default function CustomTable({
  columns,
  data,
  param,
  tableLoader = false,
  pagination = false,
  setPage,
  setSizePerPage,
  page,
  showSizeChanger = false,
  pageSizeOptions = ["10", "20", "30", "50"],
  rowKey,
  totalCount,
  sizePerPage,
  extraClassName = "",
  showNoDataIcon = true,
  ...rest
}) {
  const handleTableChange = (pagination, filters, sorter) => {
    const data = {};
    if (sorter && sorter.order) {
      data.sortBy = sorter.columnKey;
      data.sortType = sorter.order === "ascend" ? "ASC" : "DESC";
    }
    if (pagination) {
      data.page = pagination.current;
      setPage(pagination?.current);
      if (pagination?.showSizeChanger) {
        setSizePerPage(pagination?.pageSize);
      }
    }
    if (param?.name) {
      data.name = param.name;
    }
  };

  return (
    <AntTable
      locale={
        showNoDataIcon
          ? {
              emptyText: (
                <Empty
                  image={`${config.IMAGE_URL}noDataFound.svg`}
                  description="No Data Found"
                />
              ),
            }
          : { emptyText: <Empty image={""} description="" /> }
      }
      className={`${extraClassName} `}
      rowKey={rowKey}
      pagination={
        pagination && {
          current: Number(page),
          pageSize: sizePerPage,
          total: totalCount,
          showSizeChanger: showSizeChanger,
          onShowSizeChange: (current, size) => {
            setSizePerPage(size);
            setPage(1);
          },
          pageSizeOptions: showSizeChanger ? pageSizeOptions : undefined,
        }
      }
      columns={columns}
      dataSource={data}
      onChange={handleTableChange}
      loading={tableLoader}
      {...rest}
    />
  );
}
