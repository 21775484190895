import React from "react";

function StickerFormatter({ rowData, id }) {
  return (
    <>
      <div id={id} className="discountcard d-none">
        {" "}
        <div className="pop-sticker-card">
          <div className="pop-stcker-title px-3">
            <div className="pop-title my-1">
              PARLE HIDE & SEEK CHOCOLATE CHIP COOKIES 120.00 Gm Packet
            </div>
            <div>
              <p className="pop-orp-title pop-discount-title m-0">RS:</p>
              <div className="pop-orpprice-cnt">
                <div className="pop-main-price text-center">45/-</div>
                <div className="text-end pop-off-title">Off</div>
              </div>
            </div>
          </div>

          <div className="pop-mrpdiscount-cnt px-1">
            <div>
              <span className="pop-mrp">MRP: </span>
              <span className="pop-mrp-amount">1575.00</span>
            </div>
            <div>
              <span className="pop-rs ps-1">ORP:</span>{" "}
              <span className="pop-discount-title">1530.00</span>
            </div>
          </div>
          <div className="text-center">
            <span>12-Jun(0)</span>
          </div>
        </div>
        <div className="pop-sticker-card text-center px-3 my-3">
          <div className="pop-buyone-title">BUY 1</div>
          <div className="pop-buyone-title">GET 1</div>
          <div className="pop-title my-2 pop-title-buyone">
            BRITANNIA TOASTTEA PREMIUM BAKE RUSK 400 GM
          </div>
          <div className="buyone-discount-title">MRP:75/-</div>
          <div className="text-center">
            <span>12-Jun(0)</span>
          </div>
        </div>
        {/* <div className="pop-sticker-card">
          <div className="pop-stcker-title">
            <div className="super-saver-title text-center py-1">
              Super Saver
            </div>
            <div className="px-3">
              <div className="pop-super-savercnt">
                <div className="pop-title my-1">
                  PARLE HIDE & SEEK CHOCOLATE CHIP COOKIES 120.00 Gm Packet
                </div>
              </div>
              <div>
                <p className="pop-orp-title pop-discount-title m-0">RS:</p>
                <div className="pop-orpprice-cnt">
                  <div className="pop-main-price text-center pop-main-discount">
                    45/-
                  </div>
                  <div className="text-end pop-off-title">Off</div>
                </div>
              </div>
            </div>
          </div>

          <div className="pop-mrpdiscount-cnt px-1">
            <div>
              <span className="pop-mrp">MRP: </span>
              <span className="pop-mrp-amount">1575.00</span>
            </div>
            <div>
              <span className="pop-rs ps-1">ORP:</span>{" "}
              <span className="pop-discount-title">1530.00</span>
            </div>
          </div>
          <div className="text-center">
            <span>12-Jun(0)</span>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default StickerFormatter;
