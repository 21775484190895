import { Outlet } from "react-router-dom";
import { baseRoutes } from "../../../helpers/baseRoutes";
import routesMap from "../../../routeControl/userRouteMap";
import { AnalysisReport } from "../../../pages";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Books And Report",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: true,
      private: true,
      element: <Outlet />,
      children: [
        {
          path: routesMap.ANALYSIS_REPORT.path,
          name: "Analysis Reports",
          key: routesMap.ANALYSIS_REPORT.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <AnalysisReport />,
        },
        {
          path: "#",
          name: "Populer - Customer Demand And Stock Out",
          key: "#",
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: "",
        },
        {
          path: "#",
          name: "Populer - Offer Sales",
          key: "#",
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: "",
        },
        {
          path: "#",
          name: "Audit - Stock Taking Cache",
          key: "#",
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: "",
        },
        {
          path: "#",
          name: "Monitoring - POP Printing Status",
          key: "#",
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: "",
        },
      ],
    },
  ];
}
