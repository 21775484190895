import React from "react";
import { Select as AntSelect, Form, Empty } from "antd";
import { useField } from "formik";
import configure from "../../../config";

const { Option } = AntSelect;

function Select({
  children,
  icon,
  arrayOfData,
  label = "",
  name,
  setFieldValue,
  onSelect,
  validateField = false,
  callField,
  focusNext = false,
  focusId = "",
  callback,
  loading,
  onChangeValue = false,
  showSearch = false,
  ...rest
}) {
  const [field, meta, helpers] = useField(name);
  const config = { ...field, ...rest };

  if (meta && meta.touched && meta.error) {
    config.error = true;
    config.helperText = meta.error;
  }

  const handleChangeSelect = (value) => {
    helpers.setValue(value);
    if (validateField) {
      setTimeout(() => {
        callField.validateForm();
      }, 200);
    }
    if (focusNext) {
      setTimeout(() => {
        document.getElementById(focusId)?.focus();
      }, 100);
    }
    if (callback) {
      callback(value);
    }

    if (onChangeValue) {
      onChangeValue(value);
    }
  };

  return (
    <Form.Item
      className="mb-0"
      label={label}
      help={meta.error && meta?.error && meta?.touched ? meta.error : ""}
      validateStatus={config.error ? "error" : "success"}
    >
      {icon}
      <AntSelect
        notFoundContent={
          <Empty
            image={`${configure.IMAGE_URL}noDataFound.svg`}
            description="No Data Found"
          />
        }
        size="large"
        loading={loading}
        showSearch={showSearch}
        filterOption={(input, option) =>
          option?.children?.toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="children"
        {...field}
        {...rest}
        onChange={onSelect || handleChangeSelect}
      >
        {!loading &&
          arrayOfData?.length > 0 &&
          arrayOfData?.map((item) => (
            <Option
              key={item?.id || item.name}
              disabled={item?.disabled || false}
              value={item.code ||item.id || undefined}
            >
              {item?.name || item?.label}
            </Option>
          ))}
      </AntSelect>
    </Form.Item>
  );
}

export default Select;
