import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { readMoreTextShow } from "../../utils";

const ToolTip = ({ placement, id, title, count = 40 }) => {
  return (
    <>
      <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id={id}>{title}</Tooltip>}
      >
        <span>{readMoreTextShow(title, "", "", count)}</span>
      </OverlayTrigger>
    </>
  );
};

export default ToolTip;
