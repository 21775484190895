import { Outlet } from "react-router-dom";
import { baseRoutes } from "../../../helpers/baseRoutes";
import routesMap from "../../../routeControl/userRouteMap";
import { PopSticker } from "../../../pages";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Planning",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: true,
      private: true,
      element: <Outlet />,
      children: [
        {
          path: "#",
          name: "PO Receiving",
          key: "#",
          commonRoute: true,
          belongsToSidebar: false,
          private: true,
          element: "",
        },
        {
          path: routesMap.POPSTICKER.path,
          name: "POP Sticker",
          key: routesMap.POPSTICKER.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <PopSticker />,
        },
        {
          path: "#",
          name: "PO View",
          key: "#",
          commonRoute: true,
          belongsToSidebar: false,
          private: true,
          element: "",
        },
        {
          path: "#",
          name: "Docet Print",
          key: "#",
          commonRoute: true,
          belongsToSidebar: false,
          private: true,
          element: "",
        },
      ],
    },
  ];
}
