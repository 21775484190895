import React from "react";
import config from "../../config";
import CommonButton from "../Button/index.btn";

const ExclamationModal = ({ handleCloseModal, title }) => {
  document.addEventListener("keydown", function (event) {
    if (event.key === "Enter") {
      handleCloseModal();
    }
  });
  return (
    <>
      <div className="d-flex flex-column py-2">
        <div className="negativestock-image-section">
          <img
            className="negativestock-img"
            src={`${config.IMAGE_URL}exclamation.svg`}
            alt="ExclamationMark"
          />
        </div>
        <div className="py-2">
          <div className="negativestock-heading">{title}</div>
        </div>
        <div className="d-flex justify-content-center">
          <CommonButton
            className="common-btn btn-width"
            onClick={handleCloseModal}
          >
            OK
          </CommonButton>
        </div>
      </div>
    </>
  );
};

export default ExclamationModal;
