/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFetch } from "../../utils";
import { useIndexedDB } from "react-indexed-db-hook";

const Footer = () => {
  const { userDataAndStore } = useFetch({ name: "userDataAndStore" });
  const { storeItems } = useFetch({ name: "storeItems" });
  const userMode = useIndexedDB("userMode");
  const { getAll } = useIndexedDB("saveBills");
  const [tableData, setTableData] = useState([]);
  const [lastSyncTime, setLastSyncTime] = useState();
  const syncTimes = useIndexedDB("syncTime");
  const [storeData, setStoreData] = useState();
  const [modeState, setModeState] = useState();
  const getData = async () => {
    const response = await getAll().then((res) => {
      return res[0];
    });
    setTableData(response?.data);
  };

  const getSyncTime = async () => {
    let res = await syncTimes?.getAll().then((res) => {
      return res[0];
    });
    let mode = await userMode?.getAll()?.then((res) => {
      return res[0]?.mode;
    });
    setModeState(mode);
    setLastSyncTime(res?.syncTime);
  };

  useEffect(() => {
    getData();
    getSyncTime();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getData();
      getSyncTime();
    }, 5000);
    return () => clearInterval(interval);
  }, [lastSyncTime]);
  useEffect(() => {
    if (storeItems) {
      let result = userDataAndStore?.stores?.find(
        (item) => item?.wms_store_id === storeItems?.wms_store_id
      );
      setStoreData(result);
    }
  }, [storeItems]);

  return (
    <footer
      className="footer"
      style={{ background: modeState === "onLine" ? "#bd2226" : "#808080" }}
    >
      <div>
        <div className="footer-area">
          <div>{userDataAndStore?.userData?.name}</div>
          <div>Lvl: 5</div>
          <div>
            Sync Bill Pending :{" "}
            {tableData?.length === 0 || tableData?.length === undefined
              ? "0"
              : tableData?.length}
          </div>

          <div className="item-sync">R.Sync:{lastSyncTime}</div>
          <div>
            RegTo({storeData?.wms_store_id})-{storeData?.store_name}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
