import auth from "./Auth";
import billing from "./Billing";
import dashboard from "./Dashboard/index";
import salereturn from "./SaleReturn/index";
import popsticker from "./PopSticker/index";
import counterFeedback from "./CounterFeedback/index";
import checkRate from "./CheckRate/index";
import cashHandover from "./CashHandover/index";
import nointernet from "./NoInternetConnection/index";
import onlineReceiving from "./OnlineReceiving/index";
import warehouseStockTaking from "./WarehouseStockTaking/index";
import localPurchaseGrocery from "./LocalPurchaseGrocery/index";
import vegLocalPurchase from "./VegLocalPurchase/index";
import onlineOps from "./OnlineOPS/index";
import posDeviceRegister from "./PosDeviceRegister/index";
import newTransferModule from "./NewTransferModule/index";
import offerItemConvert from "./OfferItemConvert/index";
import bdeConvert from "./BDEConvert/index";
import counterFeedbackAction from "./CounterFeedbackAction/index";
import stockTakingApproval from "./StockTakingApproval/index";
import analysisReport from "./AnalysisReport/index";
import customerVOC from "./CustomerVOC/index";
import vegDispose from "./VegDispose/index";

const AccessControl = {
  ...auth,
  ...billing,
  ...dashboard,
  ...salereturn,
  ...popsticker,
  ...counterFeedback,
  ...checkRate,
  ...cashHandover,
  ...nointernet,
  ...onlineReceiving,
  ...warehouseStockTaking,
  ...localPurchaseGrocery,
  ...vegLocalPurchase,
  ...onlineOps,
  ...posDeviceRegister,
  ...newTransferModule,
  ...offerItemConvert,
  ...bdeConvert,
  ...counterFeedbackAction,
  ...stockTakingApproval,
  ...analysisReport,
  ...customerVOC,
  ...vegDispose,
};

export default AccessControl;
