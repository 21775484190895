import * as yup from "yup";

export function validation() {
  const phoneNumberRules =
    /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

  const nameRules = /^[a-zA-Z\s]+$/;

  return yup.object().shape({
    name: yup
      .string()
      .matches(nameRules, {
        message: "Please enter valid name",
      })
      .required("Name is required"),
    mobile: yup
      .string()
      .matches(phoneNumberRules, {
        message: "Please enter valid Mobile Number",
      })
      .required("Mobile number is required"),
  });
}
