import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { CommonButton, CustomDatePicker, Input as TextInput } from "../..";
import { validation } from "./validation";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { enterOnlyNumericValue } from "../../../utils";

export default function RegistrationForm({ onSubmit, city, mobileNumber }) {
  const [dob, setDOBDate] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [calendarFromOpen, setCalendarFromOpen] = useState(false);
  const datePickerRef = useRef(null);
  const datePickerFromRef = useRef(null);
  let initialValues = {
    mobile: mobileNumber || "",
    name: "",
    pincode: "",
    email: "",
    // dob:
    //   moment(dob).format("DD/MM/YYYY") !== "Invalid date"
    //     ? moment(dob).format("DD/MM/YYYY")
    //     : "",
    // anniversary:
    //   moment(anniversary).format("DD/MM/YYYY") !== "Invalid date"
    //     ? moment(anniversary).format("DD/MM/YYYY")
    //     : "",
    dob: "",
    anniversary: "",
    cardNo: "",
    address1: "",
    address2: "",
    city: city?.name || "",
  };

  return (
    <Formik
      initialValues={{ ...initialValues }}
      validationSchema={validation()}
      onSubmit={(e) => {
        let obj = { ...e };
        obj.dob =
          moment(dob).format("YYYY/MM/DD") !== "Invalid date"
            ? moment(dob).format("YYYY/MM/DD")
            : "";
        obj.anniversary =
          moment(anniversary).format("YYYY/MM/DD") !== "Invalid date"
            ? moment(anniversary).format("YYYY/MM/DD")
            : "";

        obj.city = city?.wms_store_id;
        onSubmit(obj);
      }}
      enableReinitialize
    >
      {(props) => {
        return (
          <Form>
            <Row className="p-2 signUpForm registrationForm">
              <Col md={6}>
                <div className="form-group ">
                  <label htmlFor="text" className="form-label">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    id="mobile"
                    className="form-control shadow-none"
                    name="mobile"
                    variant="standard"
                    type="text"
                    placeholder="Enter mobile number"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    min="0"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value?.length > 10) {
                        e.target.value = value?.slice(0, 10);
                      }
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    Name <span className="text-danger">*</span>
                  </label>
                  <TextInput
                    id="name"
                    className="form-control shadow-none"
                    name="name"
                    variant="standard"
                    type="text"
                    placeholder="Enter name"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value?.length > 40) {
                        e.target.value = value?.slice(0, 40);
                      }
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group ">
                  <label htmlFor="text" className="form-label">
                    Card No
                  </label>
                  <TextInput
                    id="cardNo"
                    className="form-control shadow-none"
                    name="cardNo"
                    variant="standard"
                    type="text"
                    placeholder="Enter card no"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      if (value?.length > 20) {
                        value = value?.slice(0, 20);
                      }
                      e.target.value = value;
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>

              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    Email
                  </label>
                  <TextInput
                    id="email"
                    className="form-control shadow-none"
                    name="email"
                    variant="standard"
                    type="text"
                    placeholder="Enter email"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value?.length > 35) {
                        e.target.value = value?.slice(0, 35);
                      }
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group counter-date-picker">
                  <label htmlFor="text" className="form-label">
                    DOB
                  </label>
                  <CustomDatePicker
                    ref={datePickerFromRef}
                    selected={dob}
                    setDateChange={(date) => setDOBDate(date)}
                    handleClickOutside={setCalendarFromOpen}
                    handleCalendarOpen={calendarFromOpen}
                  />
                  {/* <TextInput
                    type="date"
                    name="dob"
                    className="form-control shadow-none "
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                  /> */}
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group counter-date-picker">
                  <label htmlFor="text" className="form-label">
                    Anniversary
                  </label>
                  <CustomDatePicker
                    ref={datePickerRef}
                    selected={anniversary}
                    setDateChange={(date) => setAnniversary(date)}
                    handleClickOutside={setCalendarOpen}
                    handleCalendarOpen={calendarOpen}
                  />
                </div>
              </Col>
              <Col md={6} className="register-mt-15">
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    Address 1
                  </label>
                  <TextInput
                    id="address1"
                    className="form-control shadow-none"
                    name="address1"
                    variant="standard"
                    type="text"
                    placeholder="Enter address 1"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value?.length > 40) {
                        e.target.value = value?.slice(0, 40);
                      }
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6} className="register-mt-15">
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    Address 2
                  </label>
                  <TextInput
                    id="address2"
                    className="form-control shadow-none"
                    name="address2"
                    variant="standard"
                    type="text"
                    placeholder="Enter address 2"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value?.length > 40) {
                        e.target.value = value?.slice(0, 40);
                      }
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    Pin Code
                  </label>
                  <TextInput
                    id="pincode"
                    className="form-control shadow-none"
                    name="pincode"
                    variant="standard"
                    type="text"
                    placeholder="Enter pin code"
                    setFieldValue={props.handleChange}
                    autoComplete="off"
                    min="0"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^0-9]/g, "");
                      if (value?.length > 6) {
                        value = value?.slice(0, 6);
                      }
                      e.target.value = value;
                      props.handleChange(e);
                    }}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="text" className="form-label">
                    City
                  </label>

                  <TextInput
                    id="city"
                    className="form-control shadow-none"
                    name="city"
                    variant="standard"
                    disabled
                    type="text"
                    placeholder="Enter pin code"
                    setFieldValue={props.handleChange}
                  />
                </div>
              </Col>

              <div className="d-flex justify-content-center my-1">
                <CommonButton
                  htmlType="submit"
                  className="common-btn"
                  type="submit"
                >
                  Submit
                </CommonButton>
              </div>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
