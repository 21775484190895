import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendar } from "react-icons/fa";

const CustomDatePicker = ({ ref, selected, setDateChange, handleClickOutside, handleCalendarOpen, ...rest }) => {
    const toggleCalendar = () => {
        handleClickOutside(prev => !prev);
    };
    return (
        <>
            <div className="date-picker-wrapper">
                <DatePicker
                    {...rest}
                    ref={ref}
                    selected={selected}
                    className="form-control shadow-none date-picker-input font-style"
                    onChange={setDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    open={handleCalendarOpen}
                    onClickOutside={() => handleClickOutside(false)}
                    onSelect={() => handleClickOutside(false)}
                    onFocus={() => handleClickOutside(true)}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                />
                <button
                    type="button"
                    onClick={toggleCalendar}
                    className="calendar-icon-button"
                >
                    <FaCalendar />
                </button>
            </div>
        </>
    )
}

export default CustomDatePicker;
