export const DBConfig = {
  name: "ondoor-store-billing",
  version: 1,
  objectStoresMeta: [
    {
      store: "userDataAndStore",
      storeConfig: {
        keyPath: "loginData",
        autoIncrement: true,
        options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "storeItems",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "userMode",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "acmOffers",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "saveBills",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "paymentMode",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "syncTime",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "billHistoryList",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "cocoStoresList",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    {
      store: "headerActiveKey",
      storeConfig: {
        keyPath: "id",
        autoIncrement: true,
        // options: { unique: true },
      },
      storeSchema: [],
    },
    // {
    //   store: "storeProduct",
    //   storeConfig: {
    //     keyPath: "Barcode",
    //     autoIncrement: true,
    //     // options: { unique: true },
    //   },
    //   storeSchema: [
    //     { name: "Barcode", keypath: "Barcode", options: { unique: false } },
    //   ],
    // },
  ],
};
