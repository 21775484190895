import { useIndexedDB } from "react-indexed-db-hook";
import { useFetch } from "../../utils";
import { useEffect, useState } from "react";

const CommonSubHeader = ({
  headerTitle,
  showStoreName = true,
  subheaderHead,
}) => {
  const { getAll } = useIndexedDB("storeItems");
  const { userDataAndStore } = useFetch({ name: "userDataAndStore" });
  const [wmsStoreId, setWmsStoreId] = useState();
  const [storeName, setStoreName] = useState("");
  useEffect(() => {
    getAll().then((personsFromDB) => {
      setWmsStoreId(personsFromDB[0]?.wms_store_id);
    });
    const res = userDataAndStore?.stores?.find(
      (item) => item?.wms_store_id === wmsStoreId && item
    );
    setStoreName(res?.store_name);
  }, [wmsStoreId]);
  return (
    <>
      <div className={subheaderHead}>
        <h6 className="subheader-title m-0">{`${
          headerTitle ? headerTitle : ""
        } ${showStoreName ? `- ${storeName}` : ""}`}</h6>
      </div>
    </>
  );
};

export default CommonSubHeader;
