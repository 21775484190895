import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  LOGIN: {
    path: `${baseRoutes.userBaseRoutes}`,
  },
  SIGNUP: {
    path: `${baseRoutes.userBaseRoutes}signup`,
  },
  OTP: {
    path: `${baseRoutes.userBaseRoutes}otp-verify`,
  },

  TEST: {
    path: `${baseRoutes.userBaseRoutes}test`,
  },
};

export default accessRoute;
